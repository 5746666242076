import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ReleaseNote = makeShortcode("ReleaseNote");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <ReleaseNote date="2013-08-12" title="Canadian Postals back in database" mdxType="ReleaseNote">
      <p>{`Canadian postal codes are included in the GeoIP City databases again. We now
return the first 3 characters of the postal for Canada. We have also increased
postal coverage for Australia, France, Spain, and Switzerland`}</p>
      <p>{`The postal code coverage is approximately:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Australia, 22%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Canada, 25%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`France, 39%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Germany, 14%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Italy, 19%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Spain, 20%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`South Africa, 5%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Switzerland, 38%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`United Kingdom, 9%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`United States, 56%`}</li>

      </ul>
    </ReleaseNote>
    <ReleaseNote date="2013-08-06" title="GeoIP release delay" mdxType="ReleaseNote">
      <p>{`The Tuesday, August 6, GeoIP update is delayed one day. The databases should be
available by 6pm US Eastern time on Wednesday, August 7th. We apologize for the
delay.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2013-07-24" title="Improved international postal resolution" mdxType="ReleaseNote">
      <p>{`he GeoIP databases now returns more postal codes for Australia, France,
Germany, Italy, Spain, South Africa, Switzerland, and United Kingdom. In
addition, the latitude and longitude will reflect the center of the postal code
instead of the center of the city for these countries. The postal code coverage
is approximately:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`Australia, 20%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`France, 23%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Germany, 14%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Italy, 18%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Spain, 11%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`South Africa, 5%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Switzerland, 28%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`United Kingdom, 9%`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`United States, 56%`}</li>

      </ul>
      <p>{`On July 30th, the GeoIP databases should return postals for Canada.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2013-07-08" title="GeoLite2 City Database Available" mdxType="ReleaseNote">
      <p>{`The `}
        <a {...{
          "href": "/geoip/geoip2/geolite2/",
          "title": "GeoLite2 Free Downloadable Databases",
          "parentName": "p"
        }}>{`GeoLite2 City
database`}</a>
        {` is now available for download. This is
the first database available in our new `}
        <a {...{
          "href": "https://github.com/maxmind/MaxMind-DB",
          "title": "MaxMind DB GitHub repository",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`MaxMind DB
format`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2013-07-05" title="GeoIP2 Precision Data Available" mdxType="ReleaseNote">
      <p>{`The GeoIP2 Web Services now use more accurate GeoIP2 precision data. Testing
shows the GeoIP2 precision data accurately geolocates 1.6% more IPs to US
postal codes than GeoIP Legacy data. GeoIP2 precision data is available only in
GeoIP2 City/ISP/Organization and GeoIP Omni Web Services.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2013-05-01" title="GeoIP2 Now Available" mdxType="ReleaseNote">
      <p>{`The GeoIP2 JavaScript service is now available. This is the first publicly
available GeoIP2-based service.`}</p>
    </ReleaseNote>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      